export function HotelLogo({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 238.83 215.48"
      className={className}
      fill={fillColor}
    >
      <g>
        <g>
          <path d="M76.89,151.68v-1.88h-12.71v1.88l2.74,.34v14.14h-16.24v-14.14l2.51-.34v-1.88h-12.48v1.88l2.51,.34v33.06l-2.51,.34v1.94h12.48v-1.94l-2.51-.34v-15.05h16.24v15.05l-2.74,.34v1.94h12.71v-1.94l-2.57-.34v-33.06l2.57-.34h0Zm11.57-3.59c-2.39,0-4.05,1.88-4.05,4.27s1.65,4.22,4.05,4.22,4.05-1.88,4.05-4.39c0-2.22-1.71-4.1-4.05-4.1h0Zm3.19,12.54h-9.29v1.71l2.39,.34v22.63l-2.39,.34v1.71h11.68v-1.71l-2.39-.34v-24.68h0Zm7.47,26.73h11.68v-1.71l-2.39-.34v-36.65h-9.29v1.71l2.34,.34v34.6l-2.34,.34v1.71h0Zm25.88-34.37l-6.78,2.05v5.59h-5.07v2.51h4.9v17.44c0,4.67,1.25,7.12,6.61,7.12,2.39,0,5.24-.51,8.61-1.71v-1.82c-1.14,.29-2.34,.29-3.71,.29-3.71,0-4.56-1.31-4.56-5.42v-15.9h6.27v-2.51h-6.27v-7.64h0Zm23.77,6.9c-8.78,0-14.36,5.76-14.36,14.36,0,10.26,5.42,13.91,13.91,13.91s14.36-5.59,14.36-13.91c0-9.18-5.24-14.36-13.91-14.36h0Zm-.28,26.22c-3.93,0-6.44-3.53-6.44-12.03s2.51-12.14,6.44-12.14,6.55,3.71,6.55,12.14-2.34,12.03-6.55,12.03h0Zm45.09-.8v-16.07c0-6.04-2.34-9.12-7.58-9.12-3.93,0-6.73,1.88-9.63,4.22v-3.7h-9.35v1.88l2.39,.34v22.46l-2.39,.34v1.71h11.74v-1.71l-2.39-.34v-19.1c1.71-1.2,4.05-2.22,5.53-2.22,3.19,0,4.73,1.88,4.73,6.1v15.22l-2.34,.34v1.71h11.68v-1.71l-2.39-.34h0Z" />
          <path d="M32.6,133.37v-35.28l2.51-.34v-1.94h-8.49v1.94l2.34,.34v22.97l-18.58-25.25H0v1.88l2.51,.34v33.06l-2.51,.34v1.94H8.49v-1.94l-2.34-.34v-29.01l23.03,31.29h3.42Zm20.35,.74c6.1,0,10.49-2.91,10.49-2.91v-2.34s-3.42,1.65-7.47,1.65c-5.24,0-8.55-3.59-9.06-9.8h17.67v-.74c0-8.83-3.48-14.14-11.4-14.14s-13.96,6.1-13.96,14.54,5.53,13.74,13.74,13.74h0Zm-.63-25.54c3.53,0,4.56,5.42,4.56,9.58h-10.03c.23-5.87,2.34-9.58,5.47-9.58h0Zm60.59-.06v-1.88h-7.75v1.88l2.39,.4-6.55,16.53-7.01-16.42,2.68-.51v-1.88h-12.26v1.88l2.11,.46,1.43,3.36-5.19,13.11-7.01-16.42,2.68-.51v-1.88h-12.26v1.88l2.11,.46,10.89,24.97h2.96l7.41-17.96,7.87,17.96h2.96l10.37-25.02,2.17-.4h0Zm31.12,7.18l10.26-17.61,2.28-.34v-1.94h-8.89v1.94l2.51,.34-8.04,14.19-8.15-14.19,2.74-.34v-1.94h-13.22v1.94l2.39,.34,10.66,17.9v15.1l-2.51,.34v1.94h12.48v-1.94l-2.51-.34v-15.39h0Zm25.71-9.8c-8.78,0-14.36,5.76-14.36,14.36,0,10.26,5.41,13.91,13.91,13.91s14.36-5.59,14.36-13.91c0-9.18-5.24-14.36-13.91-14.36h0Zm-.29,26.22c-3.93,0-6.44-3.53-6.44-12.03s2.51-12.14,6.44-12.14,6.55,3.71,6.55,12.14-2.34,12.03-6.55,12.03h0Zm27.87-25.48h-9.35v1.88l2.39,.34v22.46l-2.39,.34v1.71h12.31v-1.71l-2.96-.34v-17.5c1.54-2.28,3.93-3.59,7.01-3.59l1.2,3.19h1.82v-7.07c-.57-.17-1.48-.4-2.74-.4-3.13,0-5.64,2.85-7.3,5.76v-5.07h0Zm22.69,13.05v-25.02h-9.29v1.71l2.34,.34v34.6l-2.39,.34v1.71h11.17v-1.71l-1.82-.34v-8.55l2.74-2.28,6.16,10.89-1.42,.34v1.65h11.34v-1.65l-1.99-.34-9.29-15.56,7.01-7.07,2.34-.46v-1.65h-8.61v1.65l2.34,.51-10.6,10.89h0Z" />
          <path d="M80.25,215.17l-1.27-16.56h-3.96l-4.18,12.5-4.2-12.5h-4.03l-1.27,16.56h2.86l.84-12.22,4.18,12.26h2.83l4.15-12.26,.89,12.22h3.17Zm6.34,0v-16.56h-3.19v16.56h3.19Zm17.59-8.3c0-4.87-2.98-8.26-8.74-8.26h-5.26v16.56h5.26c5.64,0,8.74-3.38,8.74-8.3h0Zm-3.24,.02c0,3.89-2.09,5.66-5.57,5.66h-2.02v-11.33h2.06c3.31,0,5.52,1.85,5.52,5.66h0Zm17.33-5.52l-.46-2.76h-12.86v2.76h4.99v13.8h3.17v-13.8h5.16Zm16.22,5.5c0-5.11-3-8.57-7.75-8.57s-7.8,3.48-7.8,8.62,3.05,8.57,7.75,8.57,7.8-3.5,7.8-8.62h0Zm-3.22,.02c0,3.62-1.7,5.95-4.56,5.95s-4.54-2.33-4.54-5.95,1.7-5.95,4.54-5.95,4.56,2.33,4.56,5.95h0Zm27.19-8.28h-3.22l-3,11.69-3.46-11.69h-3.19l-3.46,11.69-3.07-11.86-3.36,.6,4.63,16.18h3l3.67-12.1,3.67,12.1h3.02l4.75-16.61h0Zm15.77,16.56v-16.56h-2.95v10.85l-7.44-10.85h-3v16.56h2.95v-11.4l7.8,11.4h2.64Z" />
          <path d="M137.21,49.25c0-7.22-4.93-13-13.91-15.79V17.05h16.64V57.27h-5.2c1.83-2.58,2.48-5.5,2.48-8.01M114.26,0c-30.16,0-52.37,19.17-52.37,39.65,0,10.58,5.96,19.49,13.47,25.67l.57-.39c-5.95-4.92-11.33-12.63-11.33-23.2,1.57-22.41,29.21-35.04,53.77-35.04,17.39,0,48.61,4.02,48.61,33.07,0,15.16-16.2,34.88-50.22,34.88-17.42,0-30.95-9.33-30.95-22.73,0-11.61,9.09-19.87,22.64-19.87,3.74,0,7.24,.87,9.9,1.6-5.65-1.02-14.43,.43-18.97,4v19.62h16.66v-22.31c3.14,.57,5.58,1.74,7.26,2.79v19.52h4.34c-3.41,3.83-9.76,6.16-15.27,5.3v.82c15.83,1.24,22.52-6.26,22.52-14.7,0-9.93-9.32-15.77-18.85-16.96v-14.67h-16.66v14.84c-10.78,2.17-21.33,8.57-21.33,19.18,0,16.33,22.83,26.44,42.68,26.44,30.18,0,53.4-14.59,53.4-35.06C174.14,17.68,140.61,0,114.26,0" />
          <path d="M166.7,73.43c0-2.05,1.66-3.72,3.74-3.72s3.71,1.66,3.71,3.72-1.66,3.73-3.71,3.73-3.74-1.66-3.74-3.73m6.79,0c0-1.69-1.37-3.06-3.06-3.06s-3.08,1.38-3.08,3.06,1.38,3.08,3.08,3.08,3.06-1.38,3.06-3.08m-3.85,.24v1.89h-.68v-4.38h1.41c.81,0,1.63,.22,1.63,1.21,0,.51-.31,.9-.89,1.02v.02c.61,.12,.68,.39,.74,.86,.07,.42,.11,.88,.26,1.27h-.87c-.04-.25-.12-.52-.15-.78-.04-.38-.04-.72-.25-.93-.16-.18-.39-.16-.62-.18h-.58Zm.72-.68c.64-.02,.8-.29,.8-.62s-.15-.5-.7-.5h-.82v1.12h.72Z" />
        </g>
      </g>
    </svg>
  );
}
